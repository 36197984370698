* {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

.container {
  padding: 5vw;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

#dropzone {
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  flex-grow: 1;
  border-radius: 6px;
  border: dashed 6px grey;
  padding: 30px;

  background: #eee;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 72px;

  &.dragging-file {
    background: #ccc;
  }
}

#download {
  display: none;
}

#error {
  margin: 20px;
  background: #ee4444;
  padding: 20px;
  color: white;
  font-size: 20px;

  display: none;

  &.has-error {
    display: block;
  }
}
